import ICompany from '@/types/ICompany';

export default class CompaniesFactory {
  static toCompany(data: any): ICompany {
    return {
      address: data.address.address,
      cityName: data.address.city,
      countryName: data.address.countryName,
      id: data.id,
      name: data.name,
      postalCode: data.address.postalCode,
    };
  }
}
