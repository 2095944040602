import ICompany from '@/types/ICompany';
import CompaniesRepository from '@/repositories/CompaniesRepository';
import ICompaniesFilters from '@/types/ICompaniesFilters';
import CompaniesFactory from '@/factories/CompaniesFactory';
import AuthService from '@/services/AuthService';
import UserService from '@/services/UserService';
import store from '@/store/index';
import {setLocalStorage} from '@/utils/localStorage';
import {IPagination} from '@/types/IPagination';

export default class CompaniesService {
    static async getAll(params: ICompaniesFilters | IPagination): Promise<{ companies: ICompany[], totalElements: number }> {
        const {data} = await CompaniesRepository.getAll(params);
        return {
            companies: data.content.map((company: any) => CompaniesFactory.toCompany(company)),
            totalElements: data.totalElements,
        };
    }

    static async loginAs(companyId: string | number) {
        await CompaniesRepository.loginAs(companyId);
        await AuthService.refreshToken();
        await UserService.getCurrentUser();
        setLocalStorage('isLoggedAs', true);
        store.commit('user/setIsLoggedAs', true);
    }

    static async logoutAs() {
        await CompaniesRepository.logoutAs();
        await AuthService.refreshToken();
        await UserService.getCurrentUser();
        setLocalStorage('isLoggedAs', false);
        store.commit('user/setIsLoggedAs', false);
    }
}
