






























import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import ICompany from '../../types/ICompany';
import {DataTableHeader} from 'vuetify';
import CompaniesService from '@/services/CompaniesService';
import {NotificationTypes, notify, resolveError} from '@/utils/notifications';
import {IPagination} from '@/types/IPagination';

@Component({name: 'CompaniesList'})
export default class CompaniesList extends Vue {
  @Prop()
  data!: ICompany[];

  @Prop()
  loading!: boolean;

  @Prop()
  pagination!: IPagination;

  headers: DataTableHeader[] = [
    {
      text: 'SYSTEM_ID',
      value: 'id',
      sortable: false,
    },
    {
      text: 'SYSTEM_NAME',
      value: 'name',
      sortable: false,
    },
    {
      text: 'SYSTEM_COUNTRY',
      value: 'countryName',
      sortable: false,
    },
    {
      text: 'SYSTEM_ADDRESS',
      value: 'address',
      sortable: false,
    },
    {
      text: 'SYSTEM_ZIP_CODE',
      value: 'postalCode',
      sortable: false,
    },
    {
      text: 'SYSTEM_TOWN',
      value: 'cityName',
      sortable: false,
    },
    {
      text: 'SYSTEM_ACTION',
      value: 'actions',
      sortable: false,
      align: 'end',
    },
  ];
  loginsAsId: number = 0;

  @Emit('fetchData')
  refreshData() {
    return;
  }

  optionsUpdated(options: { page: number, itemsPerPage: number, sortBy: string[], sortDesc: boolean[] }) {
    this.pagination.page = options.page;
    this.pagination.size = options.itemsPerPage;
    this.pagination.sortBy = options.sortBy[0];
    this.pagination.sortDirection = options.sortDesc[0] ? 'DESC' : 'ASC';

    this.refreshData();
  }

  async loginAs(id: number) {
    try {
      this.loginsAsId = id;
      await CompaniesService.loginAs(id);
      await this.$router.push({name: 'Home'});
      notify(NotificationTypes.success, this.$t('SYSTEM_YOU_ARE_LOGGED_IN'));
    } catch (e) {
      resolveError(e, 'login');
    } finally {
      this.loginsAsId = 0;
    }
  }
}
