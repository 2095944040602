

































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';

@Component({name: 'DateRangePicker'})
export default class DateRangePicker extends Vue {
  isOpen: boolean = false;

  @Prop()
  label!: string;

  @Prop()
  value!: (string | null)[] | null;

  @Prop()
  rules!: [];

  @Prop()
  type!: string;

  @Prop()
  allowedDates!: Function

  @Emit('input')
  onDateSelect(value: (string | null)[]) {
    if (value[1]) {
      this.isOpen = false;
    }
    return value;
  }

  // formatDate (date: Date): string {
  //   if (!date) return null
  //
  //   const [year, month, day] = date.split('-')
  //   return `${month}/${day}/${year}`
  // }
}
