import axios from 'axios';
import ICompaniesFilters from '@/types/ICompaniesFilters';
import {IPagination} from '@/types/IPagination';

export default class CompaniesRepository {
  static getAll(params: ICompaniesFilters | IPagination): Promise<any> {
    return axios.post('admin/company/search', params);
  }

  static loginAs(companyId: number | string) {
    return axios.post(`admin/company/login-as/${companyId}`);
  }

  static logoutAs() {
    return axios.delete('admin/company/logout');
  }
}
